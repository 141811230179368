import React from 'react';
// Import the Font Awesome library
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <>
            <section className="py-12 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Training Partners</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-md">
                            <img src="/images/p1.jpg" alt="Image1"
                                 className="rounded-xl shadow-lg mb-4"/>
                            <p className="text-center text-gray-700"></p>
                        </div>
                        <div className="flex flex-col items-center p-6 bg-gray-100 rounded-lg shadow-md">
                            <img src="/images/p2.png" alt="Image2"
                                 className="rounded-xl shadow-lg mb-4"/>
                            <p className="text-center text-gray-700"></p>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="relative mt-20 bg-gray-900 px-4 pt-20 pb-10">
                <div
                    className="absolute -top-10 left-1/2 transform -translate-x-1/2 rounded-xl border-4 border-orange-500 bg-white p-2">
                    <img className="h-16 w-16 object-contain" src="/foot.png" alt="Logo"/>
                </div>
                <nav aria-label="Footer Navigation"
                     className="mx-auto flex max-w-lg flex-wrap justify-center gap-10 text-center text-white">
                    <a href="/" className="font-medium">Home</a>
                    <a href="/about" className="font-medium">Our Courses</a>
                    <a href="https://wa.me/917510101414" className="font-medium">Whatsapp Now</a>
                    <a href="tel:+917510101414" className="font-medium">Call Now</a>
                    <a href="mailto:greataviationacademy@gmail.com" className="font-medium">Email us</a>

                </nav>
                <div className="text-center text-gray-300 mt-10">
                    <p className="mb-2">Great I Pvt Ltd</p>
                    <p className="mb-2">NO. 46/2271, CORAL SQUARE, SOUTH BAZAR, CIVIL STATION,</p>
                    <p className="mb-2">KANNUR, KERALA-670002</p>
                    <p className="mb-2">Phone: +91-7510101414</p>
                    <p className="mb-2">Phone: +91-9447737973</p>
                    <p className="mb-2">Email: greataviationacademy@gmail.com</p>
                    <p className="mb-2">Website: www.great-i.com</p>
                    <a href="https://www.instagram.com/greati.aviationacademy"
                       className="font-medium flex items-center justify-center mt-4">
                        <FaInstagram className="mr-2"/> Instagram
                    </a>
                </div>
                <p className="py-10 text-center text-gray-300">© 2024 Great-I PVT. Ltd. | All Rights Reserved |
                    Developed by DBes Techno</p>
            </footer>
        </>
    );
}

export default Footer;