import React, { useState } from 'react';
import Call from "../Call";
import Modal from "./Modal"; // Adjust the import path as needed

const About = () => {
    const [showModal, setShowModal] = useState(false);

    const handleKnowMoreClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const categories = [
        {
            title: "Aviation Discipline",
            headerImage: "/images/avaition.png",
            courses: [
                { title: "Aviation Management", description: "Comprehensive training in aviation management, including airline operations and aviation safety and security." },
                { title: "Airline Operations", description: "In-depth understanding of airline operations and management." },
                { title: "Aviation Safety and Security", description: "Courses on maintaining safety and security in aviation." },
                { title: "Airport Ground Handling", description: "Training for airport ground handling, passenger service agents, and ramp handling." },
                { title: "Passenger Service Agent Training", description: "Focused training for passenger service agents." },
                { title: "Ramp Handling", description: "Courses on ramp handling procedures and operations." },
                { title: "Flight Attendant Certification", description: "Certification and training for flight attendants, including emergency procedures and in-flight service excellence." },
                { title: "In-Flight Service Excellence", description: "Training on providing excellent in-flight service." },
                { title: "Emergency and Safety Procedures", description: "Courses on emergency and safety procedures for cabin crew." },
                { title: "IATA Courses", description: "Courses from IATA covering various aviation topics." },
                { title: "ACI Courses", description: "Courses from ACI on airport management and operations." },
                { title: "Aviation Market Research", description: "Comprehensive market research in aviation, logistics, and the services industry." },
                { title: "International Aviation Law and Regulations", description: "In-depth courses on international aviation law and regulations." },
                { title: "Aircraft Ground Handling", description: "Training in aircraft ground handling, baggage handling, and airport ramp services." },
                { title: "Baggage Handling and Operations", description: "Courses on efficient baggage handling and operations." },
                { title: "Airport Ramp Services", description: "Training on various airport ramp services." },
                { title: "Tuition for IATA Diploma", description: "Specialized tuition for IATA diploma courses." }
            ]
        },
        {
            title: "Tourism Discipline",
            headerImage: "/images/tourism2.png",
            courses: [
                { title: "Hospitality and Tourism Management", description: "Training in hospitality and tourism management, travel and tourism operations, and event management in tourism." },
                { title: "Travel and Tourism Operations", description: "Courses on managing travel and tourism operations." },
                { title: "Event Management in Tourism", description: "Training on event management within the tourism industry." },
                { title: "Culinary Tourism", description: "Courses in culinary tourism, adventure tourism, and cruise line management." },
                { title: "Adventure Tourism", description: "Training on managing adventure tourism activities." },
                { title: "Cruise Line Management", description: "Courses on the management of cruise line operations." }
            ]
        },
        {
            title: "Logistics Discipline",
            headerImage: "/images/logistics2.png",
            courses: [
                { title: "Supply Chain Management", description: "Courses in supply chain management, logistics management, and international trade and logistics." },
                { title: "Logistics Management", description: "Training on various aspects of logistics management." },
                { title: "Logistics Market Research", description: "Market research focused on logistics and supply chain management." },
                { title: "International Trade and Logistics", description: "Courses covering international trade and logistics operations." },
                { title: "Cargo Management", description: "Training in cargo management, freight forwarding, and handling dangerous goods regulations." },
                { title: "Freight Forwarding", description: "Courses on freight forwarding procedures and practices." },
                { title: "Dangerous Goods Regulations", description: "Training on the regulations and handling of dangerous goods." }
            ]
        },
        {
            title: "Other Courses",
            headerImage: "/images/manage.png",
            courses: [


                { title: "Services Industry Market Research", description: "Research in various service industries." },
                { title: "Tuition for IGNOU Management Course", description: "Specialized tuition for IGNOU management courses." }
            ]
        }
    ];

    const Category = ({ title, headerImage, courses }) => (
        <div className="w-full mb-10">
            <h2 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{title}</h2>
            <img className="w-full mb-6" src={headerImage} alt={`${title} Header`} />
            <div className="flex flex-wrap -m-4">
                {courses.map((course, index) => (
                    <div key={index} className="xl:w-1/3 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <h3 className="text-lg text-gray-900 font-medium title-font mb-4">{course.title}</h3>
                            <p className="leading-relaxed text-base">{course.description}</p>
                            <button
                                onClick={handleKnowMoreClick}
                                className="mt-3 text-indigo-500 inline-flex items-center"
                            >
                                Know More
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    className="w-4 h-4 ml-2"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-wrap w-full mb-20">
                    <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Our Courses</h1>
                        <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                    </div>
                    <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Explore our comprehensive range of courses designed to meet the dynamic needs of the aviation, tourism, and logistics industries.</p>
                </div>
                {categories.map((category, index) => (
                    <Category key={index} title={category.title} headerImage={category.headerImage} courses={category.courses} />
                ))}
            </div>

            <Call />  {/* call to action */}
            <Modal show={showModal} onClose={handleCloseModal} />
        </section>
    );
}

export default About;
